import React, { useEffect, useState } from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql, navigate } from 'gatsby'
import { PageWrapper, HTMLRenderer } from '@retina-packages/retina-theme-bootstrap';
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import {
  ConsiderationsForTesting
} from './styles'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { hcpCFT } from './constants';
import retinaConfig from '../../../utils/retinaConfigs';
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import { EsHeaderContainer } from '../../../css/common/style';
import TabbedContent from '@retina-packages/retina-theme-bootstrap/packages/components/TabbedComponents';
import { tabDataBuilders } from '../../../helpers/Resolvers/ParagraphItemsResolvers/TabbedPage';



const navCalloutClickListener = () => {
  // Click listener for links when target element is present in another inactive tab.
  document.querySelectorAll('a.tab-changer')?.forEach((el) => {
    el.addEventListener('click', (e: any) => {
      e.preventDefault()
      // Tab where the target is present.
      const tabId = el.getAttribute('data-tab')
      const tabElement = tabId && document.getElementById(tabId)
      const link = el.getAttribute('href')
      if (tabElement && link) {
        // Go to the target tab.
        tabElement?.click()
        // Go to the required section.
        navigate(link)
        window.scrollTo(0, -1)
      }
    })
  })
}

/**
 * Rendering Considerations for Testing page template
 *
 * @param props Props
 * @returns  void
 */
const ConsiderationsTestingTemplate = (props: Props) => {
  const [renderToggle, setRenderToggle] = useState(false)

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsCFT = props.pageContext
  const htmlStaticFilesCFT = {
    nonSVGImages: pgContextsCFT.nonSVGImages,
    svgMediaImages: pgContextsCFT.svgMediaImages,
    allMediaDocument: pgContextsCFT.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpCFT.mainMenu, siteFooterMenu: hcpCFT.footerMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  //Banner Component data
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = hcpCFT.footer
  const exitPopupTitle = hcpCFT.exitPopup
  const hcpSwitcherTitle = hcpCFT.hcpSwitcher
  const topNavTitle = hcpCFT.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,

  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});

  // Retina Canada HCP - "Considerations for Testing" page - "American Academy of Ophthalmologists" Callout component
  const bannerHTML = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.banner})

  //Considerations for testing reference data
  const hcpHomeReference = {'data': fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.footerReference})}

  //Considerations for testing reference data
  const cftTopParaHTML = fullHTMLDataBuilder({blocks: blocks, title: hcpCFT.topPara})

  // Site logo
  const siteLogos = deriveMultiLogo({blocks: blocks, title: hcpCFT.siteLogo})

  // Tab content builder
  const {
    tabArr,
    tabHead
  }:
  {
    tabHead: never[]
    tabArr: any[]
  } = tabDataBuilders({blocks, tabTitle: hcpCFT.tabContent})

  const tabData = {
    tabData: tabHead,
    tabContent: tabArr
  }

  const pagewrapper = "consideration-testing"

  const updateAria = (renderTime: number) => {
    setTimeout(()=>{
      document.querySelectorAll('#tabbed-content-tabpane-tab1.show .swiper-pagination-bullet').forEach((el) => {
        // Remove the step number at the begining of aria label for second tabbed content slider.
        let _aria = el.getAttribute('aria-label')
        const afterElementStyle = window.getComputedStyle(el, ':after')
        _aria = afterElementStyle['content']
        // Remove extra quotes.
        _aria = _aria.slice(1, _aria.length-1)
        el.setAttribute('aria-label', _aria)
      })
    }, renderTime)
  }
  const updateAriaOnSliderChange = () => {
    document.addEventListener('DOMSubtreeModified', () => {
      updateAria(100)
    })
  }


  useEffect(() => {
    // Update aria label.
    updateAria(1000)
    document.querySelector('#tabbed-content-tab-tab1')?.addEventListener('click', () => {
      updateAria(1000)
      updateAriaOnSliderChange()
      navCalloutClickListener()
    })
  }, [])

  return (
    <>
      <div className='hcppage'>
      {/* site is not accessible it shows "Access denied" error message */}
      <EsHeaderContainer className="esheader-con">
      <ConsiderationsForTesting className="hcpmobilewrapper hcp-consideration-container">
        {props.pageContext !== null && props.pageContext.metaInfo !== null && (
          <MetaTagContainer metaData={props.pageContext.metaInfo} />
        )}
        <Layout
          title={"siteTitle"}
          location={props.location}
          data={mainMenu}
          mdata={footerMenu}
          footerData={footerText}
          audience={audience}
          footref={hcpHomeReference}
          exitPopData={exitData}
          modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
          hcplinks={hcpSwitcher}
          topNavigation={topNavs}
          siteLogos={siteLogos}
          backToTopContent={retinaConfig.backToTopContent}
          staticMediaFiles={htmlStaticFilesCFT}
          showRefContent={retinaConfig.showRefContent}
          hideRefContent={retinaConfig.hideRefContent}
          hcpValidate={retinaConfig.hcpValidate}
          preIndexUrl = {retinaConfig.preIndexUrl}
          hcpHomeUrl={retinaConfig.hcpHomeUrl}
          hcpPrefix={retinaConfig.hcpPrefix}
          siteHomeUrl={retinaConfig.siteHomeUrl}
          gtmBackToTopLabel={hcpCFT.backToTopGTM}
          footerClassName={hcpCFT.footerClassName}
          cpraFooter={true}
          cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
          cpraOT={true}
          cpraOTEnv={process.env.OT_ENV}
          cpraOTuuid={process.env.OT_UUID}
          {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
          <PageWrapper className={`pagewrapper ${pagewrapper}`}>
            <MobileBanner className="mobile-banner clinical-trials-banner">
              <HTMLRenderer data={htmlStaticFilesCFT} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
            </MobileBanner>
            <HTMLRenderer html={cftTopParaHTML} data={htmlStaticFilesCFT} tagName='div' />
            {/* Retina Canada HCP - "Considerations for Testing" page - "American Academy of Ophthalmologists" Callout component */}
            <div className='o-container o-top-space'>
              <TabbedContent
                data={tabData}
                location={props.location}
                mediaStaticFiles={htmlStaticFilesCFT}
                onTabChange={setRenderToggle}
                toggleRender={renderToggle}
                {...hcpCFT.tabAttrs}
              />
            </div>
          </PageWrapper>
        </Layout>
      </ConsiderationsForTesting>
      </EsHeaderContainer>
    </div>
    </>
  )
}

export default ConsiderationsTestingTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... CFTQuery
      }
    }
  }
`

