export const hcpCFT: any = {
  mainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  footer: "Footer HCP - Retina Spain",
  exitPopup: "Exit Popup - HCP - Retina Spain",
  hCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu HCP - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  topPara: "HCP - Considerations for testing - Top Para - Retina Spain",
  tabContent: "Tabbed content - CFT - HCP - Retina Spain",
  footerReference: "Reference - Considerations For Testing - Retina Spain",
  banner: "Banner - HTML - CFT - HCP - Retina Spain",
  backToTopGTM: "back to top - considerations for testing - hcp",
  tabAttrs: {
    stepContent: "Paso",
    tabGTMLabels : [{
      tabButtonGTM: {
        'data-gtm-event-label': 'genetic testing process overview tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [
        {
          iconGTMs: [
            {
              'data-gtm-event-label': 'step 1 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 2 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 3 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 4 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 5 icon',
              'className': 'gtm-accordion'
            }
          ],
          prevBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 1'
            },
            {
              'data-gtm-event-label': 'step 2 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 2'
            },
            {
              'data-gtm-event-label': 'step 3 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 3'
            },
            {
              'data-gtm-event-label': 'step 4 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 4'
            },
            {
              'data-gtm-event-label': 'step 5 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 5'
            }
          ],
          nextBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 1'
            },
            {
              'data-gtm-event-label': 'step 2 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 2'
            },
            {
              'data-gtm-event-label': 'step 3 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 3'
            },
            {
              'data-gtm-event-label': 'step 4 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 4'
            },
            {
              'data-gtm-event-label': 'step 5 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir a la paso 5'
            }
          ]
        }
      ]
    },
    {
      tabButtonGTM: {
        'data-gtm-event-label': 'discussing results tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [{
        iconGTMs: [
          {
            'data-gtm-event-label': 'before the genetic test icon',
            'className': 'gtm-accordion'
          },
          {
            'data-gtm-event-label': 'after the genetic test icon',
            'className': 'gtm-accordion'
          },
          {
            'data-gtm-event-label': 'if a genetic test is negative or inconclusive icon',
            'className': 'gtm-accordion'
          }
        ]
      }]
    }
  ]},
  footerClassName: "considerations-for-testing-footer"
}
